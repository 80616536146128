div {
    text-align: center;
    margin-top: 50px;
  }
  
  input {
    padding: 10px;
    margin-right: 10px;
  }
  
  button {
    padding: 10px;
  }
  
  p button {
    margin-left: 20px;
  }

  .red-alert{
    color: red;
    display: block;
    margin-bottom: 10px;    
  }